/* .vh-50 {
    height: 50vh;
}

.vh-80 {
    height: 80vh;
} */

/* App.css */
body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    /* background: url('./InterpineLiDAR_FGR.png') no-repeat center center fixed; */
    background: url('./pure_white_2016x1128.png') no-repeat center center fixed;
    background-size: cover;
    /* background-color: white; */
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Set to 100vh to make sure the content is vertically centered */
   }
  
  .App {
    text-align: center;
    width: 100%;
    max-width: 400px; /* Adjust the maximum width of the content */
    padding: 20px;
    background: rgba(255, 255, 255, 0.8); /* Add a semi-transparent background to make content readable */
    border-radius: 8px;
  }

  .form-label {
    background-color: rgb(217, 226, 217); /* Add white background to form labels */
    color: darkgreen;
    padding: 5px 10px; /* Add padding to form labels for spacing */
    border-radius: 4px; /* Add border-radius for rounded corners */
    margin-bottom: 5px; /* Add margin below form labels */
  }

  .label-large {
    font-size: 20px; /* Adjust the font size as needed */
  }

  .form-text {
    background-color: rgb(217, 226, 217); /* Add white background to form labels */
    padding: 5px 10px; /* Add padding to form labels for spacing */
    border-radius: 4px; /* Add border-radius for rounded corners */
    margin-bottom: 5px; /* Add margin below form labels */
  }

  .text-large {
    font-size: 20px; /* Adjust the font size as needed */
  }
  
  .form-group {
    margin-bottom: 15px; /* Add some vertical spacing between form groups */
  }

  .Container {
    background-color: rgb(129, 172, 129); /* Set the background color of the container to green */
  }

  .custom-row {
    padding: 20px; /* Adjust padding to increase row height */
    margin-bottom: 10px; /* Adjust margin to create spacing between rows */
    background-color: lightgray; /* For visualization purposes */
  }

  .custom-button {
    background-color: #ff6600; /* Replace with your custom color */
    color: white; /* Text color */
    /* Add any other styles you want */
  }

  .progress-bar {
    width: 100%;
    background-color: rgb(129,172,129);
    height: 20px;
    border-radius: 4px;
    margin-top: 10px;
    overflow: hidden;
  }
  
  .filler {
    height: 100%;
    background-color: darkgreen;
    transition: width 0.3s ease-in-out;
  }

  .dropzone {
    border: 2px dashed #ccc;
    padding: 40px; /* Increase padding to make it larger */
    text-align: center;
    background-color: #f0f0f0; /* Change the background color */
  }
  
   