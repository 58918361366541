.styled-display-text {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 10px; /* Adjust the radius to your preference */
    font-size: 20px; /* Adjust the font size as needed */
    background-color: rgb(255, 255, 255); /* Optional: Customize the background color */
    color: darkgreen; /* Optional: Customize the text color */
    /* font-style: italic; */
  }
  

/* Define custom formatting styles */
/* .styled-display-text.custom-format { */
    /* Add your custom CSS styles here */
    /* font-weight: bold; */
    /* color: darkgreen; Blue text color */
/* } */

.custom-format {
    font-weight: bold;
    color: darkgreen; /* Blue text color */   
}
  